.lp_radiobutton_wrapper {
  .lp_radio_button {
    input[type='radio'] ~ label {
      &::before {
        #{$ldirection}: -15px;
        top: 0;
      }
      &::after {
        #{$ldirection}: -10px;
        top: 5px;
      }
    }
  }
}
