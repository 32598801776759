.cs-contact-form-formatter {
  &__fieldset {
    &.address1,
    &.address2,
    &.state,
    &.city,
    &.postal_code {
      display: none;
    }
    &.phone_number {
      width: 100%;
    }
  }
}
