#ot-sdk-btn {
  &.ot-sdk-show-settings {
    background: none !important;
    border: 0 !important;
    color: $color-dark-gray !important;
    font-size: 15px !important;
    padding: 0 !important;
    text-decoration: underline;
    &:hover {
      color: $color-green !important;
      text-decoration: none;
    }
    &:focus {
      outline: 0;
    }
  }
}
