.sitewide-footer-formatter {
  &__trustmark {
    display: none;
  }
  &__trustmark-wrapper {
    &.trustmark-logo-wrapper {
      display: block;
      margin-top: 40px;
      text-align: center;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0 auto 0 80px);
        order: 4;
        width: auto;
      }
    }
    img {
      width: 150px;
      @include breakpoint($landscape-down) {
        margin: 56px 0;
      }
    }
  }
  &__terms-links {
    &.footer-links {
      margin: 0;
    }
  }
  .footer-language-toggle {
    .active {
      text-decoration: underline;
    }
  }
  .footer-country-link {
    @include breakpoint($landscape-up) {
      width: 143px;
    }
  }
}
